export default {
  inserted: (el) => {
    const skeletonClass = 'skeleton'; // Skeleton için bir CSS sınıfı

    function loadImage() {
      const imageElement = Array.from(el.children).find(
        el => el.nodeName === 'IMG',
      );
      if (imageElement) {
        // Yapay gecikme eklemek için setTimeout kullanıyoruz
        setTimeout(() => {
          imageElement.src = imageElement.dataset.src;
          imageElement.onload = () => {
            el.classList.remove(skeletonClass); // Yükleme tamamlandığında skeleton kaldırılır
          };
        }, 200); // 200ms gecikme (isteğe göre artırıp azaltabilirsiniz)
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          el.classList.add(skeletonClass); // Skeleton sınıfını ekle
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    const observer = new IntersectionObserver(handleIntersect);
    observer.observe(el);
  },
};
