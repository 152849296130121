import lodashDebounce from 'lodash.debounce';

export const isValueFilled = (value, options = {}) => {
  if (Array.isArray(value)) {
    return value.length > 0;
  }

  const values = (() => {
    if (options.justNullishValues) {
      return [null, undefined, '', ' '];
    }
    return [null, false, undefined, '', ' '];
  })();

  return values.every(type => type !== value);
};

export const getNameFromNotation = name => name.split('.').pop();

export const isArraysEquals = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const produceKey = () => Math.random().toString(36).substr(2, 9);

export const isObject = value => !Array.isArray(value) && value === Object(value);

export const hasHTMLContent = value => isValueFilled(value.replace(/(<([^>]+)>)/gi, '').trim());

export const makeUppercaseFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

export const unlinkReference = value => JSON.parse(JSON.stringify(value));

export const arrayEquals = (a, b) => Array.isArray(a)
  && Array.isArray(b)
  && a.length === b.length
  && a.every((val, index) => val === b[index]);

export const convertToQueryString = data => Object.keys(data).map(key => `${key}=${data[key]}`).join('&');

export const copyUrl = (vue, selector) => {
  const testingCodeToCopy = document.querySelector(selector);
  testingCodeToCopy.setAttribute('type', 'text');
  testingCodeToCopy.select();

  try {
    document.execCommand('copy');
    vue.successMessage('Copied to clipboard!');
  } catch (err) {
    vue.errorMessage('Error!');
  }

  /* unselect the range */
  testingCodeToCopy.setAttribute('type', 'hidden');
  window.getSelection().removeAllRanges();
};

export const convertExactType = (value) => {
  // eslint-disable-next-line no-restricted-globals
  if (typeof value === 'boolean' || isNaN(value)) {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }

    return value;
  }
  return +value;
};

export const makeFileSizeVerbose = (size) => {
  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(size) / Math.log(k));
  // eslint-disable-next-line
  return parseFloat(( size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isDevelopment = process.env.VUE_APP_LIFESYCLE_ENVIRONMENT_TYPE === 'development';

export const isTestEnvironment = process.env.NODE_ENV === 'development' || window.location.hostname.match(/.test-/gi);

export const debounce = (functionInstance, ms = 1500) => lodashDebounce(functionInstance, ms);

export function getNestedValue(obj, path, fallback) {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

export function getObjectValueByPath(obj, path, fallback) {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== 'string') return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  return getNestedValue(obj, path.split('.'), fallback);
}

export function createSimpleFunctional(
  c,
  el = 'div',
  name,
) {
  return {
    name: name || c.replace(/__/g, '-'),

    functional: true,

    props: {
      tag: {
        type: String,
        default: el,
      },
    },

    render(h, { data, props, children }) {
      data.staticClass = (`${c} ${data.staticClass || ''}`).trim();

      return h(props.tag, data, children);
    },
  };
}

export function convertToUnit(str, unit = 'px') {
  if (str == null || str === '') {
    return undefined;
    // eslint-disable-next-line no-restricted-globals
  } if (isNaN(+str)) {
    return String(str);
  }
  return `${Number(str)}${unit}`;
}

export function getNumberWithOrdinal(n) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function buildQuery(queryObj) {
  const rbracket = /\[\]$/;
  const class2type = {};

  function toType(obj) {
    if (obj == null) {
      return `${obj}`;
    }

    // Support: Android <=2.3 only (functionish RegExp)
    return typeof obj === 'object' || typeof obj === 'function'
      ? class2type[toString.call(obj)] || 'object'
      : typeof obj;
  }

  function isFunction(obj) {
    return typeof obj === 'function' && typeof obj.nodeType !== 'number';
  }

  function isArrayLike(obj) {
    const length = !!obj && 'length' in obj && obj.length;
    const type = toType(obj);

    if (isFunction(obj)) {
      return false;
    }

    return type === 'array' || length === 0
      // eslint-disable-next-line no-mixed-operators
      || typeof length === 'number' && length > 0 && (length - 1) in obj;
  }
  function each(obj, callback) {
    let length; let
      i = 0;

    if (isArrayLike(obj)) {
      // eslint-disable-next-line prefer-destructuring
      length = obj.length;
      // eslint-disable-next-line no-plusplus
      for (; i < length; i++) {
        if (callback.call(obj[i], i, obj[i]) === false) {
          break;
        }
      }
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (i in obj) {
        if (callback.call(obj[i], i, obj[i]) === false) {
          break;
        }
      }
    }

    return obj;
  }

  function buildParams(prefix, obj, traditional, add) {
    let name;
    if (Array.isArray(obj)) {
      // var buildParams = this.buildParams
      // Serialize array item.
      each(obj, (i, v) => {
        if (traditional || rbracket.test(prefix)) {
          // Treat each array item as a scalar.
          add(prefix, v);
        } else {
          // Item is non-scalar (array or object), encode its numeric index.
          buildParams(
            `${prefix}[${typeof v === 'object' && v != null ? i : ''}]`,
            v,
            traditional,
            add,
          );
        }
      });
    } else if (!traditional && toType(obj) === 'object') {
      // Serialize object item.
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (name in obj) {
        if (obj[name] === true || obj[name] === false) obj[name] = obj[name] === true ? '1' : '0';
        else if (obj[name]) {
          if (Object.keys(obj[name]).length === 0) obj[name] = '';
          else if (!obj[name]) obj[name] = '';
        }

        buildParams(`${prefix}[${name}]`, obj[name], traditional, add);
      }
    } else {
      // Serialize scalar item.
      add(prefix, obj);
    }
  }

  function param(a, traditional) {
    let prefix;
    const s = [];
    // eslint-disable-next-line func-names
    const add = function (key, valueOrFunction) {
      // If value is a function, invoke it and use its return value
      const value = typeof valueOrFunction === 'function' && typeof valueOrFunction.nodeType !== 'number'
        ? valueOrFunction()
        : valueOrFunction;

      s[s.length] = `${encodeURIComponent(key)}=${
        encodeURIComponent(value)}`;
    };

    if (a == null) {
      return '';
    }

    // If an array was passed in, assume that it is an array of form elements.
    if (Array.isArray(a) /* || ( a.jquery && !jQuery.isPlainObject( a ) ) */) {
      // Serialize the form elements
      // eslint-disable-next-line func-names
      this.each(a, function () {
        add(this.name, this.value);
      });
    } else {
      // If traditional, encode the "old" way (the way 1.3.2 or older
      // did it), otherwise encode params recursively.
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (prefix in a) {
        buildParams(prefix, a[prefix], traditional, add);
      }
    }

    // Return the resulting serialization
    return s.join('&');
  }

  // eslint-disable-next-line func-names
  return (function (a, traditional) {
    function replacer(key, value) {
      // Filtering out properties
      if (typeof value === 'number') {
        return `${value}`;
      }
      return value;
    }
    const stringfy = JSON.stringify(a, replacer);
    const encode = JSON.parse(`${stringfy}`);
    return param(encode, traditional);
  }(queryObj));
}

export const computeFileExtension = (currentExtension) => {
  const extension = (() => {
    if (currentExtension === 'xlsx') {
      return 'xls';
    }

    if (currentExtension === 'jpeg') {
      return 'jpg';
    }

    if (currentExtension === 'doc' || currentExtension === 'txt') {
      return 'docx';
    }

    return currentExtension;
  })();

  return `/img/files/${extension}.svg`;
};


export const findLastIndex = (arr, predicate) => arr.map(item => predicate(item)).lastIndexOf(true);

export function setLocalStorageData(name, values) {
  return localStorage.setItem(name, JSON.stringify(values));
}

export function getLocalStorageData(name) {
  const parsedData = localStorage.getItem(name);
  return JSON.parse(parsedData);
}

export const currencyFormat = (num) => {
  if (num) return `£${num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  return '£0';
};


export const copyUrlV2 = async (vue, text, selector = null) => {
  try {
    await navigator.clipboard.writeText(text);
    vue.successMessage('Copied to clipboard!');
  } catch (e) {
    copyUrl(vue, selector);
  }
};

export const runModal = (id) => {
  $(id).modal({
    backdrop: 'static',
    keyboard: false,
  });
};

export const hideModal = (id) => {
  $(id).modal('hide');
};

export const checkMediaLink = (link) => {
  const regex = /^(https?:\/\/(?:www\.)?vimeo\.com\/\d+)/;

  const match = link.match(regex);
  if (match && match[0]) {
    return match[0];
  }
  return link;
};

