import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueSweetalert2 from 'vue-sweetalert2';
import * as VueGoogleMaps from 'vue2-google-maps';
import { EmojiPickerPlugin } from 'vue-emoji-picker';
import FBSignInButton from 'vue-facebook-signin-button';
import VueSignature from 'vue-signature-pad';
import dayjs from 'dayjs';

import Breakpoint from '@/core/services/breakpoint/breakpoint.service';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import App from './App.vue';
import router from './router/index';
import store from './stores/index';

import SelectWatcher from './mixins/SelectWatcher';
import MessageManager from './mixins/MessageManager';
import HelperMixin from './mixins/HelperMixin';
import FiltersMixin from './mixins/FiltersMixin';
import RequestManager from './mixins/RequestManager';
import AuthorizationController from './mixins/AuthorizationController';
import DataControllerManager from './mixins/DataControllerManager';
import ValidatorManager from './mixins/ValidatorManager';
import ErrorHandlerService from './core/services/logger/errorHandler.service';
import LazyLoadDirective from './core/utils/directives/lazyLoad';

const HelloJs = require('hellojs/dist/hello.all.min.js');
const VueHello = require('vue-hellojs');

const VueApexCharts = () => import('vue-apexcharts');
const Vue2Editor = () => import('vue2-editor');
const PartialLoader = () => import('@/modules/common/components/loader/PartialLoader.vue');

Vue.prototype.$ls = {};

const breakpoint = new Breakpoint({
  mobileBreakpoint: 1264,
  scrollBarWidth: 5,
  thresholds: {
    xxs: 375,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
  },
});
breakpoint.init();

Vue.prototype.$ls.breakpoint = Vue.observable(breakpoint);

Vue.component('apexchart', VueApexCharts);

Vue.use(VueCompositionAPI);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS,
    libraries: 'places',
    language: 'en',
    region: 'GB',
    country: 'gb',
  },
});
Vue.directive('lazyload', LazyLoadDirective);
HelloJs.init({
  twitter: process.env.VUE_APP_TWITTER_CONSUMER_KEY,
}, {
  redirect_uri: process.env.VUE_APP_REDIRECT_URL,
  oauth_proxy: process.env.VUE_APP_OAUTH_PROXY,
});
Vue.use(VueHello, HelloJs);

Vue.use(FBSignInButton);

Vue.mixin(SelectWatcher);
Vue.mixin(MessageManager);
Vue.mixin(HelperMixin);
Vue.mixin(FiltersMixin);
Vue.mixin(RequestManager);
Vue.mixin(AuthorizationController);
Vue.mixin(DataControllerManager);
Vue.mixin(ValidatorManager);
Vue.use(Vue2Editor);

Vue.component('google-map', VueGoogleMaps.Map);
Vue.component('google-marker', VueGoogleMaps.Marker);
Vue.component('partial-loader', PartialLoader);

Vue.use(VueSweetalert2);
Vue.use(require('vue-moment'));

Vue.use(VueApexCharts);
Vue.config.productionTip = true;
Vue.use(EmojiPickerPlugin);
Vue.use(VueSignature);
Vue.use(dayjs);
Vue.use(CKEditor);


const vueApp = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');


Vue.config.errorHandler = (error, vm, info) => ErrorHandlerService.onError(error, vm, info);


export default vueApp;
