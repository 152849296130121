export default {
  data() {
    return {
      types: [
        'success',
        'error',
        'info',
        'general',
        'warning',
      ],
    };
  },
  methods: {
    /**
     * @param message {string}
     * @param type {'success'|'error'|'info'|'warning'}
     */
    showMessage(message, type) {
      if (type === 'success') {
        this.successMessage(message);
      } else if (type === 'error') {
        this.errorMessage(message);
      } else if (type === 'info') {
        this.infoMessage(message);
      } else if (type === 'error') {
        this.errorMessage(message);
      } else if (type === 'warning') {
        this.warningMessage(message);
      } else {
        this.generalMessage(message);
      }
    },
    successMessage(message, timer = 5000) {
      let messageControl = message;
      if (!messageControl) {
        messageControl = 'Transaction successful';
      }
      this.$swal.fire({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        timer,
        animation: false,
        html: messageControl,
        background: '#77a734',
        title: '<i class="far fa-check fa-lg"></i>',
      });
    },

    successMessageV2(message, confirmationFunc) {
      let messageControl = message;
      if (!messageControl) {
        messageControl = 'Transaction successful';
      }
      this.$swal.fire({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: true,
        animation: false,
        timer: 10000,
        html: messageControl,
        confirmButtonText: 'Check',
        background: '#77a734',
        title: '<i class="far fa-check fa-lg"></i>',
      }).then((result) => {
        if (result.value) {
          confirmationFunc(result);
        }
      });
    },

    infoMessage(message) {
      let messageControl = message;
      if (!messageControl) {
        messageControl = 'Info';
      }
      this.$swal.fire({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        timer: 5000,
        animation: false,
        text: messageControl,
        background: '#6eadff',
        title: '<i class="far fa-info-circle fa-lg"></i>',
      });
    },
    errorMessage(message) {
      let messageControl = message;
      if (!messageControl) {
        messageControl = 'Something wrong';
      }
      this.$swal.fire({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        timer: 5000,
        animation: false,
        html: messageControl,
        background: '#d91b1b',
        title: '<i class="far fa-debug fa-lg"></i>',
      });
    },
    warningMessage(message) {
      let messageControl = message;
      if (!messageControl) {
        messageControl = 'Warning';
      }
      this.$swal.fire({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        timer: 5000,
        animation: false,
        html: messageControl,
        background: '#fc861e',
        title: '<i class="far fa-exclamation-circle"></i>',
      });
    },
    generalMessage(message) {
      let messageControl = message;
      if (!messageControl) {
        messageControl = 'Info';
      }
      this.$swal.fire({
        toast: true,
        position: 'bottom-right',
        showConfirmButton: false,
        timer: 5000,
        animation: false,
        text: messageControl,
        background: '#000',
        title: '<i class="far fa-comment fa-lg"></i>',
      });
    },

    permissionErrorMessage() {
      this.$swal.fire({
        showConfirmButton: true,
        html: '<p style="text-align: center; font-size: 20px; padding: 20px 0 10px 0;" >You do not have access to this feature, please contact to your administrator to give you access.</p>',
        confirmButtonText: 'OK',
        confirmButtonColor: '#000000',
        cancelButtonColor: '#000000',
        background: 'white',
        color: 'black',
        title: '<i class="fad fa-lock-alt " style="color:black; font-size: 70px"></i>',
      });
    },

    uploadCounter(uploaded, total) {
      const messageControl = `${uploaded}/${total} files uploaded`;
      // Calculate the percentage
      const percentage = ((uploaded / total) * 100);

      const progressBarHtml = `
    <div style="width: 100%; height: 20px; background-color: white; border:1px solid #ed0080; border-radius: 5px; overflow: hidden; position: relative;">
      <div style="height: 100%; width: ${percentage}%;  background-color: #ed0080;"></div>
      <div style="position: absolute; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; z-index: 1;">
        ${percentage.toFixed(0)}%
      </div>
    </div>
    <div style="text-align: center; margin-top: 5px;">
      ${messageControl}
    </div>
  `;

      // Fire a new toast with the updated HTML
      this.$swal.fire({
        toast: false,
        position: 'bottom-right',
        showConfirmButton: false,
        showCloseButton: false, // Prevent close button
        allowOutsideClick: false, // Prevent closing by clicking outside
        animation: false,
        html: progressBarHtml,
        background: 'white',
        backdrop: false,
      });
    },
  },
  watch: {},
};
